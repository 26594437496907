import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { LoadingBackdrop } from "../../../components/loading-backdrop";
import ApplicationLayoutContext from "../../../context/application-layout-context";
import { useMyBrokerInfo } from "../../../hooks/broker.hooks";
import { useScenarioList } from "../../../hooks/scenario.hooks";
import { CancelDocument } from "../../../svgs";
import BrokerDetail from "../start-loan-application/components/broker-detail";

const getFileExtension = (fileName) => {
  return fileName.split(".").pop();
};

const convertFileType = (fileExtension) => {
  switch (getFileExtension(fileExtension)) {
    case "jpg":
    case "jpeg":
    case "png":
      return "image";
    case "pdf":
      return "pdf";

    default:
      return "unknown";
  }
};

const generateMessage = (page) => {
  switch (page) {
    case "loan-application":
      return "Please create a scenario to start loan application.";
    case "application-process":
      return "Please create a scenario to see your application process";
    default:
      return "Please create a scenario to see your buying power.";
  }
};

const ApplicationLayout = ({
  children,
  page = "",
  applicationStep,
  setApplicationStep,
}) => {
  const {
    data: scenarioList,
    isLoading: gettingScenarioList,
  } = useScenarioList();
  const { data: brokerInfo } = useMyBrokerInfo();
  const [selectedImage, setSelectedImage] = useState({
    url: "",
    extension: "",
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess(file) {
    const { numPages: numberOfPage = 0 } = file;
    setNumPages(numberOfPage);
  }

  const handleBackPdf = () => {
    if (pageNumber > 1) setPageNumber((prevState) => prevState - 1);
  };
  const handleNextPdf = () => {
    if (pageNumber < numPages) setPageNumber((prevState) => prevState + 1);
  };

  const handleViewPhoto = (fileUrl, type) => {
    setSelectedImage({
      url: fileUrl,
      extension: type,
    });
  };

  const handleResetState = () => {
    setSelectedImage({
      url: "",
      extension: "",
    });
  };

  return (
    <ApplicationLayoutContext.Provider
      value={{
        handleViewPhoto,
        handleResetState,
        applicationStep,
        setApplicationStep,
      }}
    >
      {scenarioList?.length > 0 && (
        <div className="loan-application-container">
          <div className="loan-application-context">{children}</div>
          <BrokerDetail
            name={brokerInfo.name}
            phoneNumber={brokerInfo.phone}
            email={brokerInfo.email}
            introductionVideoUrl={brokerInfo.introductionVideoUrl}
            calendlyUrl={brokerInfo.calendlyUrl}
          />
        </div>
      )}
      {gettingScenarioList && (
        <div className="position-relative w-100">
          <LoadingBackdrop />
        </div>
      )}
      {scenarioList?.length === 0 && !gettingScenarioList && (
        <div className="no-lender-option ">{generateMessage(page)}</div>
      )}
      <div
        className={classNames("file-view", {
          isShow: selectedImage.url,
        })}
        onClick={() => handleResetState()}
        onKeyDown={() => {}}
        tabIndex="0"
        role="link"
      >
        <div className="file-modal-container">
          <div className="pdf-container" onClick={(e) => e.stopPropagation()}>
            {convertFileType(selectedImage.extension) === "image" && (
              <img src={selectedImage.url} alt="experity-document" />
            )}
            {convertFileType(selectedImage.extension) === "pdf" && (
              <>
                <Document
                  file={selectedImage.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} size="A4" wrap />
                </Document>

                <div className="bg-white">
                  <div className="text-center d-flex justify-content-center py-3">
                    <div
                      role="button"
                      onClick={handleBackPdf}
                      tabIndex="0"
                      onKeyDown={() => {}}
                    >
                      <ChevronLeftIcon />
                    </div>
                    <span className="mx-3">
                      Page {pageNumber} / {numPages}
                    </span>
                    <div
                      role="button"
                      onClick={handleNextPdf}
                      tabIndex="0"
                      onKeyDown={() => {}}
                    >
                      <ChevronRightIcon />
                    </div>
                  </div>
                </div>
              </>
            )}
            {convertFileType(selectedImage.extension) === "unknown" && (
              <div className="unsupport-file-container">
                <CancelDocument />
                <p className="font-weight-semibold mt-3">
                  We can not view your file. Please download this file to watch.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </ApplicationLayoutContext.Provider>
  );
};

ApplicationLayout.propTypes = {};

export default ApplicationLayout;
