import React from "react";
import { openPopupWidget } from "react-calendly";
import CallToActionButton from "../../../../components/CallToActionButton/call-to-action-button";
import WistiaVideoPlayer from "../../../../components/wistia-video-player";
import { Calendar } from "../../../../svgs";

const BrokerDetail = (brokerdetail) => {
  const {
    name,
    phoneNumber,
    email,
    introductionVideoUrl,
    calendlyUrl,
  } = brokerdetail;
  const handleClickCalendly = () => {
    openPopupWidget({ url: calendlyUrl });
  };
  return (
    <div className="broker-detail-container">
      <div className="broker-detail-title">
        <span className="broker-detail-name">{name} |</span> Mortgage Broker
      </div>
      {Boolean(introductionVideoUrl) && (
        <div className="broker-detail-video">
          <WistiaVideoPlayer embedId={introductionVideoUrl} />
        </div>
      )}

      <div className="broker-detail-title">M: {phoneNumber}</div>
      <div className="broker-detail-title">E: {email}</div>
      {Boolean(calendlyUrl) && (
        <div>
          <CallToActionButton
            title="Book Call Back"
            icon={<Calendar />}
            onClick={() => {
              handleClickCalendly();
            }}
          />
        </div>
      )}
    </div>
  );
};

BrokerDetail.propTypes = {};

export default BrokerDetail;
