import React from "react";
import { isBrowser } from "../utils/miscellaneous-util";

const insertScriptHead = ({ name, src }) => {
  if (!document.querySelector(`#${name}`)) {
    const container = document.head || document.querySelector("head");
    const scriptElement = document.createElement("script");
    scriptElement.setAttribute("id", name);
    scriptElement.async = true;
    scriptElement.src = src;
    container.appendChild(scriptElement);
  }
};

const wistiaScriptsHandler = () => {
  const requiredScripts = [
    {
      name: "wistia-script",
      src: "https://fast.wistia.com/assets/external/E-v1.js",
    },
  ];
  requiredScripts.forEach((v) =>
    insertScriptHead({
      name: v.name,
      src: v.src,
    })
  );
};

class WistiaVideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    if (isBrowser()) {
      const {
        embedId,
        isMuted,
        isAutoPlay = false,
        setVideoState = () => {},
        shouldShowPlayButton = true,
      } = this.props;
      window._wq = window._wq || [];
      window._wq.push({
        id: embedId,
        onReady: (video) => {
          this.handle = video;
          video.bind("play", () => {
            setVideoState("playing");
          });
          video.bind("pause", () => {
            setVideoState("paused");
          });
          video.videoQuality(1080);
        },
        options: {
          controlsVisibleOnLoad: false,
          autoPlay: isAutoPlay,
          endVideoBehavior: "loop",
          muted: isMuted,
          playButton: shouldShowPlayButton,
        },
      });
    }
  }

  componentDidMount() {
    const { embedId } = this.props;
    wistiaScriptsHandler(embedId, this.wrapper);
  }

  componentWillUnmount() {
    this.handle && this.handle.remove();
  }

  render() {
    const { embedId, isClickDisabled = false } = this.props;
    return (
      <div className="wistia_responsive_padding">
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            width: "100%",
            pointerEvents: isClickDisabled ? "none" : "auto",
          }}
        >
          <div
            className={`wistia_embed wistia_async_${embedId}  chromeless=true`}
            style={{ width: "100%", height: "100%", position: "relative" }}
          />
        </div>
      </div>
    );
  }
}

export default WistiaVideoPlayer;
